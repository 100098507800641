const workHistory = [
  {
    role: "Senior BI Consultant",
    company: "Proservartner",
    contents: [
      "Designed and developed the Monthly Invoice Backlog Power BI report, providing actionable insights to improve decision making and reduce delays.",
      "Collaborated with data teams to combine data from multiple sources, ensuring data accuracy and completeness.",
      "Optimized data models and reduced report size using advanced techniques to improve performance.",
      "Created in-depth reports using DAX for insightful analysis and business needs translation",
      "Performed extensive data cleaning and transformation for accurate and reliable reporting.",
      "Delivered 4 Tableau projects (Bot Utilization, Operations Dashboard, Project Pipeline) implementing basic data transformation, Actions, and LOD expressions.",
      "Developed 2 Looker dashboards to enhance data visualization.",
      "Led client meetings, mentored interns, and conducted Daily Hurdle meetings to track goals and targets.",
      "Earned certifications: Scrum Master",
    ],
    duration: "Nov 2023 - Present",
    cta: "PROSERVARTNER",
    url: "https://www.proservartner.co.uk/",
  },
  {
    role: "Power BI Developer",
    company: "Reliance General Insurance",
    // responsibility:
    //   "As a Power BI Consultant, my expertise includes Data Cleaning, Data Modeling, Report Building, Visualization, Power BI Dashboard, Power BI Service, Dataset Refresh, RLS Security, MySQL, and many more",
    // daywork:
    //   "I created daily booking reports using Power BI, which relied on data from sources such as Excel and SQL. These reports offered insights into key performance indicators, such as month-on-month growth for each segment within the company and how they performed against their targets. To ensure data privacy and security have implemented Row Level security to restrict access to specific data. Lastly have automated these reports by using a scheduled refresh.",

    contents: [
      "Built Power BI reports for data analysis and visualization, enabling data-driven decision-making.",
      "Collaborated with management to understand business objectives and reporting needs.",
      "Performed data cleaning, formatting, and established data connections for accurate reporting.",
      "Gathered stakeholder feedback and continuously improved reporting processes.",
      "Automated report refreshes and implemented data security using Row-Level Security (RLS).",
      "Set up Schedule Refresh and Incremental Refresh for timely report updates.",
    ],
    duration: "Jun 2022 - Nov 2023",
    cta: "RELIANCE GENERAL INSURANCE",
    url: "https://www.reliancegeneral.co.in/insurance/home.aspx",
  },
  {
    role: "Power BI Developer",
    company: "Freelance",
    contents: [
      " Led multiple projects, ensuring high-quality results and client satisfaction.",
      "Developed a Sales Data Power BI dashboard, providing actionable insights for decision-making.",
      "Supported clients and professionals on Power BI projects, including data modeling, cleaning, and performance tuning",
      "Created interactive online learning activities in Python, Web Development.",
      " Delivered training sessions to teachers, equipping them to teach Python and related subjects effectively.",
      " Configured data sources, managed Power BI workspaces, and optimized performance and cost.",
      " Implemented Role-Level Security (RLS) to control data access and safeguard sensitive information.",
      " Provided training on Power BI basics, DAX, and effective visualizations.",
    ],

    duration: "May 2018 - Jun 2022",
    cta: "RECOMMENDATION",
    url: "https://shravari.com/",
  },
];

export { workHistory };
