const projectData = [
  {
    title: "Northwestern Karnataka Road and Transport Corporation",
    contents: [
      "Designed and implemented an automated data collection system using Power Automate, facilitating approval workflows for bus depot operations data.",
      "Created a flow where data from forms was submitted to managers for approval; if approved, the data was stored in SharePoint, otherwise, users received a notification to resubmit the form. The system efficiently handled errors and ensured data integrity.",
      "Developed a comprehensive Power BI dashboard using the approved SharePoint data. o Analyzed bus depot operations, tracking bus schedules, cancellations, and on-time performance.",
      "Monitored revenue on a daily, monthly, and yearly basis.",
      "Implemented tracking for staff attendance, accidents, and vehicle breakdowns. ",
      " Improved project efficiency and resource allocation. ",
      "Provided real-time insights to improve operational efficiency and support decision-making for the Karnataka Government (NWKRTC).",
    ],
    imagePath: "ksrtc.png",
    url: "https://app.powerbi.com/view?r=eyJrIjoiNzIxZWRkNGQtYzlhOS00M2VjLTgxOTYtNWI3ZmY5ZGZmYTJiIiwidCI6ImFhYjJkYThhLWYwMjEtNGJiOS04NzJjLWY3YmRiYzUxM2EzNyJ9",
  },
  {
    title: " BOE - Adani & Dalmia Dashboards (Construction Dashboards)",
    contents: [
      "Developed construction dashboards in Power BI for Adani and Dalmia, providing real-time project insights and KPIs.",
      "Gathered and integrated data from various sources. o Designed charts for project timelines, budget utilization, and resource allocation.",
      "Created interactive filters for detailed analysis.",
      " Implemented features to track critical milestones.",
      " Collaborated with stakeholders to enhance usability.",
      " Improved project efficiency and resource allocation. ",
      "Created 5 dashboards for Adani and 2 for Dalmia, with 7 active and 11 inactive pages for each dashboard.",
    ],
    imagePath: "adaniGroup.png",
    url: "https://app.powerbi.com/view?r=eyJrIjoiOThkMzBiZGUtODMxYS00NWRiLThjYjYtZDJmOTU0MTM2OGI5IiwidCI6ImRiNWFjYmRmLTdmNmUtNDY5MC1iMjM2LWQ1NmJiYWUxMjJmYiJ9",
  },
  {
    title: "Hotel Management Dashboard",
    content:
      "Developed an interactive Power BI dashboard for a hotel management system. The dashboard provided key insights into customer demographics, including customer trends over the years, types of customers, and reasons for their trips. It also visualized hotel financials, tracking monthly revenue, profit and loss, and overall performance. This project enabled stakeholders to easily monitor and improve hotel operations by providing actionable data insights.",
    imagePath: "hotleManagement.png",
    url: "https://app.powerbi.com/view?r=eyJrIjoiZDYzOTNmN2ItOWI2Ny00YWJlLTg3YjYtYTZlYjZjYTQ2YWExIiwidCI6ImRiNWFjYmRmLTdmNmUtNDY5MC1iMjM2LWQ1NmJiYWUxMjJmYiJ9",
  },
  {
    title: "Travel Dashboard",
    content:
      "As a developer, I was responsible for developing a Power BI dashboard for a travel agency using Excel data. My role involved ensuring that the data was accurate and validated, and testing the dashboard to ensure that it met the client's requirements. I was also responsible for managing client meetings, gathering feedback, and implementing changes to the dashboard to ensure that it met their needs. This involved listening carefully to their requirements and using my expertise to create a dashboard that met their specific needs.",
    imagePath: "traveldashboard.svg",
    url: "https://app.powerbi.com/view?r=eyJrIjoiMjk4YzE3MWEtMTZmYy00MTE4LWE4YjUtNDE4YmRjZWJlMTM3IiwidCI6ImRiNWFjYmRmLTdmNmUtNDY5MC1iMjM2LWQ1NmJiYWUxMjJmYiJ9",
  },
  {
    title: "HR Dashboard",
    content:
      "I designed and developed an intuitive Power BI dashboard that enables HR professionals to gain valuable insights into employee details. The dashboard was created by leveraging Excel data, which I extracted and transformed to ensure data accuracy and consistency.",
    imagePath: "hrdashboard.svg",
    url: "https://app.powerbi.com/view?r=eyJrIjoiMGIyODYwMzAtZjIzMi00NTIwLWJlNDgtYTlmMjY5OGU4OWFiIiwidCI6ImRiNWFjYmRmLTdmNmUtNDY5MC1iMjM2LWQ1NmJiYWUxMjJmYiJ9",
  },
  {
    title: "Employee Dashboard",
    content:
      "I designed and developed an intuitive Power BI dashboard that enables HR professionals to gain valuable insights into employee details. The dashboard was created by leveraging Excel data, which I extracted and transformed to ensure data accuracy and consistency.",
    imagePath: "hrdashboard2.svg",
    url: "https://app.powerbi.com/view?r=eyJrIjoiNzU5MWRmNGEtN2E4YS00MTFmLWIzYjctOGJiZDE3Y2MwOGIxIiwidCI6ImRiNWFjYmRmLTdmNmUtNDY5MC1iMjM2LWQ1NmJiYWUxMjJmYiJ9",
  },
  {
    title: "Sales Dashboard",
    content:
      "Developed a Power BI dashboard to analyze B2B sales data, utilizing various analytical techniques such as time series, clustering, and classification. Conducted testing and validation of the data to ensure accuracy and reliability. Facilitated client meetings to gather feedback and implemented changes to the dashboard accordingly.",
    imagePath: "salesdashboard.svg",
    url: "https://app.powerbi.com/view?r=eyJrIjoiY2E0Y2EwZTgtMDY5OC00MGZlLWE0ZjMtNmE1MThkOWZkMjNjIiwidCI6ImRiNWFjYmRmLTdmNmUtNDY5MC1iMjM2LWQ1NmJiYWUxMjJmYiJ9",
  },
  {
    title: "More Care More Kids (Hospital Dashboard)",
    content:
      "I designed and developed an intuitive Power BI dashboard that enables HR professionals to gain valuable insights into employee details. The dashboard was created by leveraging Excel data, which I extracted and transformed to ensure data accuracy and consistency.",
    imagePath: "kidsdashboard.svg",
    url: "https://app.powerbi.com/view?r=eyJrIjoiNzcyMWI4YWYtNmM3Mi00ZDZiLThjMzgtZDJjMzlkOTg0YTY0IiwidCI6ImRiNWFjYmRmLTdmNmUtNDY5MC1iMjM2LWQ1NmJiYWUxMjJmYiJ9",
  },
  {
    title: "AMI Polymer Dashboard",
    content:
      "I have designed and developed an intuitive Power BI dashboard for AMI Polymer that enables them to track the finances,employees and sales.",
    imagePath: "AmiPolymerDashboard.svg",
    url: "https://app.powerbi.com/view?r=eyJrIjoiNzk3ZmJjNzEtOTBjZS00NDMwLTg1NzItN2U5Y2FkMWEyNDNkIiwidCI6ImRiNWFjYmRmLTdmNmUtNDY5MC1iMjM2LWQ1NmJiYWUxMjJmYiJ9",
  },
  {
    title: "Godrej Dashboard",
    content:
      "I have designed and developed an intuitive Power BI dashboard for godrej for keeping tracks of the consumer products.",
    imagePath: "godrejDashboard.svg",
    url: "https://app.powerbi.com/view?r=eyJrIjoiZTIyNThmMjMtM2ZmMi00NzJmLTk2NDktNmIwNjU5MDZlOGQ5IiwidCI6ImRiNWFjYmRmLTdmNmUtNDY5MC1iMjM2LWQ1NmJiYWUxMjJmYiJ9",
  },
  {
    title: "TelemedRx Dashboard",
    content:
      "I have designed and developed an intuitive Power BI dashboard for TelemedRx.",
    imagePath: "telemedRxDashboard.svg",
    url: "https://app.powerbi.com/view?r=eyJrIjoiNGQyYTI3MWItYWQ4Ny00MTNjLTgzYzQtMTE1ZTNmZGYxNTBmIiwidCI6ImRiNWFjYmRmLTdmNmUtNDY5MC1iMjM2LWQ1NmJiYWUxMjJmYiJ99",
  },
  {
    title: "Intern Dashboard",
    content:
      "I have designed and developed an intuitive Power BI dashboard for keeping track of their interns.",
    imagePath: "internDashboard.svg",
    url: "https://app.powerbi.com/view?r=eyJrIjoiOWY4MDdiN2QtOWU5Yy00YTEzLTlkOTYtMmEzZTRmMDc2MGY5IiwidCI6ImRiNWFjYmRmLTdmNmUtNDY5MC1iMjM2LWQ1NmJiYWUxMjJmYiJ9",
  },
];

export { projectData };
