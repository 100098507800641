export const services = [
  {
    id: 1,
    title: "Data Analysis",
    description:
      "Turning data into insights is at the heart of what I do. With years of experience, I specialize in analyzing complex datasets, identifying patterns, and providing actionable recommendations. My data analysis services help you uncover trends, optimize business processes, and make informed decisions with confidence.",
    icon: "FaChartLine", // Icon name from React Icons
    src: "dataAnalytics",
  },
  {
    id: 2,
    title: "Power BI",
    description:
      "I create visually engaging, interactive Power BI dashboards that transform raw data into meaningful insights. From data modeling to custom DAX calculations, I design end-to-end Power BI solutions that empower businesses to monitor performance, identify opportunities, and drive growth through data.",
    icon: "FaPowerOff",
    src: "powerBi",
  },
  {
    id: 3,
    title: "Tableau",
    description:
      "With Tableau, I bring your data to life through dynamic visualizations and interactive dashboards. Whether you're tracking KPIs or exploring in-depth analytics, I help you create user-friendly Tableau dashboards that allow for deep data exploration, driving informed decision-making across your business.",
    icon: "FaTable",
    src: "dataAnalytics",
  },
  {
    id: 4,
    title: "Power Automate",
    description:
      "Streamline and automate your workflows with Power Automate. I design custom automations to improve efficiency, reduce manual tasks, and ensure smooth processes within your organization. From approval workflows to data synchronization, my Power Automate services can help optimize your daily operations.",
    icon: "FaRocket",
    src: "powerAutomate",
  },
  {
    id: 5,
    title: "Power Apps",
    description:
      "Empower your team with custom apps tailored to your business needs using Power Apps. I build easy-to-use applications that simplify tasks, improve productivity, and enable better decision-making. Whether it's a simple form app or a more complex system, I can help create powerful apps without heavy coding.",
    icon: "FaMobileAlt",
    src: "powerApps",
  },
];
