// src/components/ServiceCard.jsx

import React from "react";
import PropTypes from "prop-types";
import dataAnalyticsImg from "../../../assets/services/dataAnaytics.png";
import powerAppsImg from "../../../assets/services/powerApps.png";
import powerBiImg from "../../../assets/services/powerBi.png";
import powerAutomateImg from "../../../assets/services/powerAutomate.png";

const images = {
  dataAnalytics: dataAnalyticsImg,
  powerBi: powerBiImg,
  tableau: dataAnalyticsImg,
  powerApps: powerAppsImg,
  powerAutomate: powerAutomateImg,
};

const ServiceCard = ({ title, description, src, url }) => {
  const imageUrl = images[src];
  return (
    <div className="cursor-pointer bg-radial-gradient border border-btn-gradient  shadow-lg rounded-lg p-6 flex flex-col justify-between hover:scale-105 transform transition-transform duration-300">
      <div>
        <div className="flex items-center mb-4">
          <img
            src={imageUrl}
            alt={`${title} icon`}
            className="w-12 h-12 mr-3"
          />
          <h3 className="text-xl font-semibold text-white">{title}</h3>
        </div>
        <p className="text-gray-300 text-justify">{description}</p>
      </div>
    </div>
  );
};

ServiceCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  url: PropTypes.string,
};

ServiceCard.defaultProps = {
  url: null,
};

export default ServiceCard;
