import React from "react";
import { FaLinkedin, FaEnvelope, FaPhoneAlt } from "react-icons/fa";

export default function Footer() {
  return (
    <footer className="bg-btn-gradient w-full py-6">
      <div className="w-11/12 sm:w-10/12 sm:w-8/12 gap-4 grid sm:grid-cols-2 lg:grid-cols-4 mx-auto items-center justify-center">
        <div className="flex items-center space-x-2">
          <span className="text-lg font-semibold text-white">
            Let's connect :)
          </span>
        </div>

        <div className="flex items-center space-x-2">
          <FaPhoneAlt className="text-white" />
          <a
            href="tel:+91 9987712859"
            className="text-lg font-semibold text-white hover:underline"
          >
            +91 9987712859
          </a>
        </div>

        <div className="flex items-center space-x-2">
          <FaEnvelope className="text-white" />
          <a
            href="mailto:shravarip@gmail.com"
            className="text-lg font-semibold text-white hover:underline"
          >
            shravarip@gmail.com
          </a>
        </div>

        <div className="hidden sm:flex items-center lg:justify-center space-x-2">
          <a
            href="https://in.linkedin.com/in/shravari?trk=public_profile_recommendations"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center space-x-1 text-lg text-white hover:text-blue-400 transition-colors duration-200"
            aria-label="Visit our LinkedIn Profile"
          >
            <FaLinkedin size={24} />
            <span className="inline">LinkedIn</span>
          </a>
        </div>
      </div>
    </footer>
  );
}
