import { Slide } from "react-awesome-reveal";
import ServiceCard from "./ServiceCards";
import { services } from "../../../resources/serviceData";
export default function MyServices() {
  return (
    <div className="pb-[5em] mt-[2rem] w-10/12 mx-auto">
      <Slide>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mx-auto gap-4">
 {
          services.map((service, index) => {
            return <ServiceCard title={service.title} description={service.description} icon={service.icon} src={service.src} />;
          })
        }
      </div>
       
      </Slide>
    </div>
  );
}
