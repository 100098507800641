const aboutMe = {
  about: [
    "Hi, I’m Shravari, a passionate Data Analyst with over 6 years of experience turning raw data into actionable insights. I’ve worked on Power BI projects end-to-end, from data collection to building dynamic reports that fuel smart decision-making. As a Computer Science graduate from Mumbai, India, I’m always ready to dive into new tools and techniques, constantly sharpening my skills in Power BI, Tableau, and Power Platforms.",
    "When I’m not knee-deep in data, you’ll find me embracing life’s fun side—whether it’s trekking through nature, smashing a badminton game, or even hanging upside down in a former gymnast move! And, of course, I’m a Marvel movie fan who might just be daydreaming about saving the world one dashboard at a time. Sitting by the ocean, with a cool breeze and the sound of waves, is my ultimate reset button.",
    "An animal lover and dance enthusiast, I thrive on mixing the technical with the creative. With an insatiable curiosity and a love for learning, I’m on a mission to make data not just accessible but also fun for everyone I work with—because who says data can’t be exciting?",
  ],
};

const aboutWork = {
  whatIdo: [
    "I help organizations in their Digital Transformation journey:",
    "Manage the Project from End-to-End.",
    "Deployment of Power BI dashboards with Modern UI/UX",
    "Data Cleaning and transformation",
    "Reporting & Dashboarding with Augmented Reality",
    "Power BI one-on-one Training",
    "Power BI Corporate Trainings",
  ],
};

const guest = {
  title: "Are you looking for a guest speaker ?",
  content:
    "If you are looking for a guest speaker, you have come to the right place. I have successfully delivered many guest talks. I have done Sessions on Power BI, did Workshop, Webinars, One-on-One Training and Corporate Trainings as well.",
};

const story = {
  myStory: [
    "I've always been fascinated by data. I love the way it can tell stories and reveal hidden insights. When I was in college, I studied computer engineering with the goal of becoming a data scientist.",
    "One day, I was introduced to Power BI, a tool for data analysis and visualization. I was immediately hooked. Power BI allowed me to take my passion for data to the next level. I could use it to create stunning visualizations that helped people understand complex data sets.",
    "I started taking freelance gigs, helping clients with their data analytics needs. I also started contributing to the Power BI community by writing blog posts and creating tutorials.",
    "The Leap of Faith",
    "After a few years, I decided to take a leap of faith and become a full-time freelancer. It was a scary decision, but I knew it was the right thing for me. I wanted to be my own boss and have the freedom to work on projects that I was passionate about.",
    "Freelancing was an amazing experience. I got to work with a variety of clients from all over the world. I learned a lot and grew as a data professional.",
    "Finding Stability and Security",
    "After a few years, I started to crave stability and security. I wanted to be part of a team and work on long-term projects. That's why I decided to join Reliance General Insurance as a Power BI Developer.",
    "At Reliance General Insurance, I get to use my skills to help the company make better decisions. I'm also part of a team of talented individuals who are passionate about using data to make a difference.",
    "The Road Ahead",
    "I'm excited to see what the future holds. I'm eager to continue using my skills to help people and make a positive impact on the world.",
    "I believe that data is the key to solving some of the world's biggest problems. I want to use my skills to help organizations make better decisions, improve their efficiency, and create a more sustainable future.",
    "I'm also passionate about helping others learn about the power of data. I want to inspire people to use data to make a difference in their own lives and communities.",
    "I'm just getting started on my journey, but I'm excited to see where it takes me.",
  ],
};
export { aboutMe, aboutWork, guest, story };
