// src/components/ExperienceSection.tsx

import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Container from "../Container";
import { Education } from "../../resources/Education";
import { workHistory } from "../../resources/Work";
import { Slide } from "react-awesome-reveal";
import { FaChevronRight } from "react-icons/fa";
import { FaBriefcase, FaGraduationCap } from "react-icons/fa";

export default function ExperienceSection() {
  return (
    <section className="pb-20 mt-12 md:mt-20">
      <Container>
        <div className="flex flex-col space-y-16">
          {/* Work History Section */}
          <div>
            <Slide>
              <h2 className="text-2xl font-bold py-10">Work History</h2>
            </Slide>
            <VerticalTimeline layout="1-column-left">
              {workHistory.map((element, index) => (
                <VerticalTimelineElement
                  key={index}
                  contentStyle={{
                    background: "rgba(211, 211, 211, 0.5)",
                  }}
                  contentArrowStyle={{
                    borderRight: "7px solid rgba(211, 211, 211, 0.5)",
                  }}
                  iconStyle={{ background: "#B99FC6", color: "#fff" }}
                  icon={<FaBriefcase />}
                >
                  <h3 className="text-3xl text-[#3B2667]">{element.role}</h3>
                  <h4 className="font-medium py-2">{element.company}</h4>
                  <div className="btn-gradient px-4 py-2 rounded-full my-5 w-fit">
                    {element.duration}
                  </div>
                  {element.contents && (
                    <ul className="list-disc ml-6 flex flex-col space-y-1">
                      {element.contents.map((tech, idx) => (
                        <li key={idx} className="text-base">
                          {tech}
                        </li>
                      ))}
                    </ul>
                  )}
                  <p className="text-base mt-4">{element.responsibility}</p>
                  {element.daywork && (
                    <p className="text-base mt-4">{element.daywork}</p>
                  )}
                  {element.content1 && (
                    <p className="text-base mt-4">{element.content1}</p>
                  )}
                  {element.content2 && (
                    <p className="text-base mt-4">{element.content2}</p>
                  )}
                  {element.cta && (
                    <a
                      href={element.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex flex-row pt-5 items-center text-underline transition-colors duration-200"
                      aria-label={`Visit ${element.cta}`}
                    >
                      <span className="text-md md:text-lg">
                        {element.cta}
                      </span>
                      <FaChevronRight className="w-6 h-6 ml-2" />
                      {/* <FaExternalLinkAlt className="w-5 h-5 ml-1" /> */}
                    </a>
                  )}
                </VerticalTimelineElement>
              ))}
            </VerticalTimeline>
          </div>

          {/* Education & Certification Section */}
          <div>
            <Slide>
              <h2 className="text-2xl font-bold py-10">
                Education & Certification
              </h2>
            </Slide>
            <VerticalTimeline layout="1-column-left">
              {Education.map((element, index) => (
                <VerticalTimelineElement
                  key={index}
                  contentStyle={{
                    background: "rgba(211, 211, 211, 0.5)",
                  }}
                  contentArrowStyle={{
                    borderRight: "7px solid rgba(211, 211, 211, 0.5)",
                  }}
                  iconStyle={{ background: "#B99FC6", color: "#fff" }}
                  icon={<FaGraduationCap />}
                >
                  <h3 className="text-3xl text-[#3B2667]">{element.title}</h3>
                  <h4 className="font-thin">
                    {element.certificationName}
                  </h4>
                   <div className="btn-gradient px-4 py-2 rounded-full my-5 w-fit">
                    {element.duration}
                  </div>
                  {element.content && (
                    <p className="mb-3 text-base">{element.content}</p>
                  )}
                  {element.cta && (
                    <a
                      href={element.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex flex-row pt-5 items-center text-underline transition-colors duration-200"
                      aria-label={`Visit ${element.cta}`}
                    >
                      <span className="text-md md:text-lg">
                        {element.cta}
                      </span>
                      <FaChevronRight className="w-6 h-6 ml-2" />
                      {/* <FaExternalLinkAlt className="w-5 h-5 ml-1" /> */}
                    </a>
                  )}
                </VerticalTimelineElement>
              ))}
            </VerticalTimeline>
          </div>
        </div>
      </Container>
    </section>
  );
}
